import Attachment from '@/components/fileUploading/attachment';
import { getLinkedOrderNumber } from '@/pages/p/commerce/invoiceEstimate/lineItems';
import { Box, Chip, Divider, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { map } from 'lodash-es';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import currencyFormat from '../../../../../helpers/currencyFormat';
import { linkFormat } from '../../../../../helpers/linkFormat';
import type { SimpleLineItem } from '../../../../../types/schema';

export default function LineItemsOrder( { lineItems, pickup }: { lineItems?: SimpleLineItem[], pickup?: boolean } ) {
	const lineItemCategories: string[] = lineItems
		?.map( ( lineItem ) => lineItem.category?.name || undefined )
		?.filter( ( name, index, self ) => self.indexOf( name ) === index ) || [];
	
	const { t } = useTranslation();
	return (
		<Paper sx={{ overflow: 'hidden', boxShadow: 0 }}>
			<Box sx={{ display: 'flex', bgcolor: 'divider', px: 2, py: 1 }}>
				<Typography sx={{ width: '80%' }}>{t( 'common:product' )}</Typography>
				{!pickup && <Typography sx={{ width: '20%', textAlign: 'right' }}>{t( 'common:price' )}</Typography>}
			</Box>
			{Boolean( lineItems.length ) ? map( lineItemCategories, ( category, index ) => (
				<Box key={index} sx={{ '.MuiTableCell-root ': { border: 0 } }}>
					{category && (
						<Box>
							<Typography
								sx={{
									px          : 1,
									pt          : 1,
									pb          : 0,
									borderRadius: 1,
									color       : 'text.secondary',
									fontSize    : '12px !important',
									fontWeight  : 'bold',
								}}>
								{category}
							</Typography>
						</Box>
					)}
					{lineItems?.filter( ( lineItem ) => lineItem.category?.name === category )
						.map( ( lineItem, index ) => {
							
							const {
								      linkInOrderName,
								      cloverOrderNumber,
							      } = getLinkedOrderNumber( lineItem );
							
							return (
								<Fragment key={lineItem.id}>
									<Box sx={{ display: 'flex', px: 2, pt: 1 }}>
										<Box sx={{ width: '80%' }}>
											{cloverOrderNumber && linkInOrderName
												? linkFormat( lineItem.name, cloverOrderNumber, `https://${process.env.NEXT_PUBLIC_DEV
													? 'sandbox.dev'
													: 'www'}.clover.com/r/${cloverOrderNumber}` )
												: (
													<Typography
														sx={{
															maxWidth  : 370,
															whiteSpace: 'pre-line',
														}}>
														{lineItem.name}
													</Typography>
												)}
											<Stack direction='row' spacing={1}>
												{lineItem.code && (
													<Typography
														sx={{
															color: 'text.secondary',
														}}>
														#{lineItem.code}
													</Typography>
												)}
												{lineItem?.status && (
													<Tooltip title={`This item is tagged as ${lineItem.status}`}>
														<Chip
															variant='alpha'
															// color={`${colorsMap[ lineItem.status ]} !important`}
															color='primary'
															label={lineItem.status}
														/>
													</Tooltip>
												)}
												<Typography sx={{ mr: 1 }} color='text.secondary'>
													{lineItem.unit} {lineItem.quantity} x {currencyFormat( lineItem.price )}
												</Typography>
											</Stack>
										</Box>
										{!pickup && (
											<Box sx={{ width: '20%', textAlign: 'right' }}>
												<Typography>
													{currencyFormat( lineItem.price * lineItem.quantity )}
												</Typography>
											</Box>
										)}
									</Box>
									{lineItem.modifierGroups
										?.map( ( mGroup ) => mGroup.modifiers
											?.filter( ( modifier ) => lineItem.metadata?.[ modifier.externalId ] )
											?.map( ( modifier, index ) => (
												<Box key={index} sx={{ display: 'flex', color: 'text.secondary', px: 2, pb: 0 }}>
													<Typography sx={{ width: '80%' }}>
														{`${modifier.name} ${!pickup ? modifier.isPercent
															? `(${modifier.value}%)`
															: ` - ${currencyFormat( modifier.value )} ${lineItem.metadata?.[ modifier.externalId ]
																? `x ${lineItem.metadata?.[ modifier.externalId ] * lineItem.quantity}`
																: `x ${lineItem.quantity}`}` : ''}`}
													</Typography>
													{!pickup && (
														<Typography sx={{ textAlign: 'right', width: '20%' }}>
															{modifier.isPercent
																? currencyFormat( modifier.value * ( lineItem.price * lineItem.quantity / 100 ) )
																: currencyFormat( modifier.value * ( lineItem.metadata?.[ modifier.externalId ] * lineItem.quantity || 1 ) )}
														</Typography>
													)}
												</Box>
											) ) )}
									{lineItem.prices
										?.filter( ( price ) => !price.metadata?.externalTax )
										?.map( ( fee, index ) => (
											<Box key={index} sx={{ display: 'flex', color: 'text.secondary', px: 2, pb: 0 }}>
												<Typography sx={{ width: '80%' }}>
													{`${fee.name} ${!pickup
														? fee.isPercent ? `(${fee.value}%)` : ` - $${fee.value}`
														: ''}`}
												</Typography>
												{!pickup && (
													<Typography sx={{ textAlign: 'right', width: '20%' }}>
														{fee.isPercent
															? currencyFormat( fee.value * ( lineItem.price * lineItem.quantity / 100 ) )
															: currencyFormat( fee.value ) + ` x ${lineItem.quantity}`}
													</Typography>
												)}
											</Box>
										) )}
									{( lineItem.image || lineItem.description ) && (
										<Stack sx={{ pl: 2, my: 1, justifyContent: 'space-between' }} direction='row'>
											{cloverOrderNumber && !linkInOrderName
												? linkFormat( lineItem.description, cloverOrderNumber, `https://${process.env.NEXT_PUBLIC_DEV
													? 'sandbox.dev'
													: 'www'}.clover.com/r/${cloverOrderNumber}` )
												: lineItem.description || lineItem.image && (
													<Typography
														sx={{
															color     : 'text.secondary',
															pb        : 1,
															maxWidth  : 370,
															pr        : 2,
															whiteSpace: 'pre-line',
														}}>
														{lineItem.description}
													</Typography>
												)}
											{lineItem.image && (
												<Attachment
													removeDownload
													src={lineItem.image}
													imageSX={{
														width    : 60,
														height   : 60,
														objectFit: 'cover',
														mb       : 0,
														mr       : 1,
													}}
												/>
											)}
										</Stack>
									)}
									{index !== lineItems?.length - 1 && <Divider/>}
								</Fragment>
							);
						} )}
				</Box>
			) ) : <Typography textAlign='center' sx={{ p: 2 }}>{t( 'common:no-items' )}</Typography>}
		</Paper>
	);
}
